body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

div#sceneContainer {
  width: 100%;
  height: 100%;
}
